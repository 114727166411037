import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./guard/auth-guard.service";
import { ReporteCalificacionSilleteroComponent } from "./shared/components/reporte-calificacion-silletero/reporte-calificacion-silletero.component";
import { AuthGuard } from "./guard/auth.guard";

const routes: Routes = [
  // {
  //   path: "welcome",
  //   loadChildren: () => import('./public/welcome/welcome.module').then(m => m.WelcomePageModule)
  // },
  {
    path: "login",
    canActivate: [AuthGuard],
    loadChildren: () => import('./public/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: "sign-up",
    canActivate: [AuthGuard],
    loadChildren: () => import('./public/sign-up/sign-up.module').then(m => m.SignUp2PageModule)
  },
  {
    path: 'silleteros',
    loadChildren: () => import('./silleteros/silleteros-tabs.module').then(m => m.SilleterosTabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./silleteros/silleteros-tabs/tabs/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: 'report/:code',
    canActivate: [AuthGuardService],
    component: ReporteCalificacionSilleteroComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
