export const environment = {
  production: false,
  urlDB: "https://wldc-app.firebaseio.com",
  urlrootFunctions: "https://wldc-app.uc.r.appspot.com",
  _production: true,
  keyDB: "e80KrfBzougyucvWjnIi",
  firebaseConfig: {
    apiKey: "AIzaSyC1MxrCfXicJ8gxAOMJut2fGouYH0QjMao",
    authDomain: "wldc-app.firebaseapp.com",
    databaseURL: "https://wldc-app.firebaseio.com",
    projectId: "wldc-app",
    storageBucket: "wldc-app.appspot.com",
    messagingSenderId: "1046500872667",
    appId: "1:1046500872667:web:39c435daa2f776166f17fe",
    measurementId: "G-8YQ92LN4ZK"
  },
  zoom: {
    SDK_KEY_SDK: 'GrwJ9r42vwhGUA8jMkEGGYvmHedDlWGdOLSY',
    SDK_SECRET_SDK: 'a5G5YfFPslV4zm0E6az9MU3JmkVHVN4q94VN',
    userName: 'bnfmeetings@gmail.com',
    password: ''
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
