import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CustomizationfileService } from "../customizationfile/customizationfile.service";

export interface MyData {
  name: string;
  filepath: string;
  size: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  // Upload Task 
  task: AngularFireUploadTask;

  // Progress in percentage
  percentage: Observable<number>;

  // Snapshot of uploading file
  snapshot: Observable<any>;

  // Uploaded File URL
  UploadedFileURL: Observable<string>;

  //Uploaded Image List
  images: Observable<MyData[]>;

  //File details  
  fileName: string;
  fileSize: number;

  //Status check 
  isUploading: boolean;
  isUploaded: boolean;
  data_music: any

  private musicCollection: AngularFirestoreCollection<MyData>;

  constructor(
    private storage: AngularFireStorage,
    private database: AngularFirestore,
    public _cf: CustomizationfileService) {
    this.isUploading = false;
    this.isUploaded = false;

    //Set collection where our documents/ images info will save
    if (this._cf.getKeyDb()) {
      this.musicCollection = database.collection('event_music').doc(this._cf.getKeyDb()).collection<MyData>('music_list')
      this.images = this.musicCollection.valueChanges();
    }
  }

  /**
   * 
   * @param event 
   * @returns 
   */
  uploadFile(event: FileList) {
    return new Promise(async (resolve, reject) => {

      try {
        const file = event.item(0)
        console.log("file", file)
        console.log('Validation', file.type.split('/')[0])
        // Validation for Images Only



        this.isUploading = true;
        this.isUploaded = false;


        this.fileName = file.name;
        console.log("fileName", this.fileName)

        // The storage path
        const path = `music/${this._cf.getKeyDb()}/${new Date().getTime()}_${file.name.trim()}`;
        console.log("path", path)

        // Totally optional metadata
        const customMetadata = { app: `Music Upload Events ${this._cf.getName()}` };

        //File reference
        const fileRef = this.storage.ref(path);

        // The main task
        this.task = this.storage.upload(path, file, { customMetadata });

        // Get file progress percentage
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
          finalize(() => {
            // Get uploaded file storage path
            this.UploadedFileURL = fileRef.getDownloadURL();
            this.UploadedFileURL.subscribe(resp => {
              this.isUploading = false;
              this.isUploaded = true;
              resolve(resp)
            }, err => {
              reject(err)
            })
          }), tap(snap => {
            this.fileSize = snap.totalBytes;
            console.log("fileSize", this.fileSize)
          })
        )
        resolve(true)
      } catch (error) {
        reject(error)
      } finally {
        resolve(true)
      }
    })
  }


  /**
   * no tocar 
   * @param event 
   * @returns 
   */
  uploadFile2(event: FileList) {
    return new Promise(async (resolve, reject) => {

      try {
        const file = event.item(0)
        console.log("file", file)
        console.log('Validation', file.type.split('/')[0])
        // Validation for Images Only



        this.isUploading = true;
        this.isUploaded = false;


        this.fileName = file.name;
        console.log("fileName", this.fileName)

        // The storage path
        const path = `music/${this._cf.getKeyDb()}/${new Date().getTime()}_${file.name.trim()}`;
        console.log("path", path)

        // Totally optional metadata
        const customMetadata = { app: `Music Upload Events ${this._cf.getName()}` };

        //File reference
        const fileRef = this.storage.ref(path);

        // The main task
        this.task = this.storage.upload(path, file, { customMetadata });

        // Get file progress percentage
        this.task.snapshotChanges().pipe(
          finalize(async () => {
            console.log("snapshotChanges")
            // Get uploaded file storage path
            const musicUrl = await fileRef.getDownloadURL().toPromise();
            console.log("musicUrl", musicUrl)
            resolve(musicUrl)
            // this.UploadedFileURL = fileRef.getDownloadURL();
            // this.UploadedFileURL.subscribe(resp => {
            //   this.isUploading = false;
            //   this.isUploaded = true;
            //   console.log("resp", resp)

            //   resolve(resp)
            // }, err => {
            //   reject(err)
            // })
          }))
      } catch (error) {
        console.log("error", error)
        reject(error)
      }
    })
  }


  /**
   * 
   * @param blob 
   * @param filePath 
   */
  uploadBlob(blob: Blob, filePath: string, type?: string) {
    return new Promise(async (resolve, reject) => {
      console.log("uploadBlob", { blob, filePath, type })
      const fileRef = this.storage.ref(filePath);

      // Subir el blob a Firebase
      const task = this.storage.upload(filePath, blob);

      // Monitorizar el progreso de la subida
      task.percentageChanges().subscribe(progress => {
        console.log(`Blob is ${progress}% uploaded`);
      });

      // Obtener la URL de descarga del blob una vez que se ha subido
      task.snapshotChanges().pipe(
        finalize(() => fileRef.getDownloadURL().subscribe(downloadURL => {
          console.log(`Blob available at: ${downloadURL}`);
          resolve({
            type: type,
            url: downloadURL
          })
        }))
      ).subscribe();
    })

  }

  /**
   * 
   * @param base64 
   * @param contentType 
   * @returns 
   */
  base64ToBlob(dataURI: string, contentType: string = ''): Blob {
    // Pasar la cadena base64 a un string decodificado
    const base64Image = dataURI.split(',')[1];  // dataURI es el resultado de canvas.toDataURL()

    const sliceSize = 1024;
    const byteCharacters = atob(base64Image);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
