import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenResultsPipe } from './hidden-results.pipe';
import { IsResultPipe } from './is-result.pipe';
import { SanitizeAudioPipe } from './sanitize-audio.pipe';
import { TermsAndConditionPipe } from './terms-and-condition.pipe';
import { OnlyNamePipe } from './only-name.pipe';
import { ProfilePipe } from './profile.pipe';
import { GetVideoPipe } from './get-video.pipe';
import { GetNameSilletasPipe } from './get-name-silletas.pipe';



@NgModule({
  declarations: [
    HiddenResultsPipe,
    IsResultPipe,
    SanitizeAudioPipe,
    TermsAndConditionPipe,
    OnlyNamePipe,
    ProfilePipe,
    GetVideoPipe,
    GetNameSilletasPipe,
    GetNameSilletasPipe
  ],
  exports: [
    HiddenResultsPipe,
    IsResultPipe,
    SanitizeAudioPipe,
    TermsAndConditionPipe,
    OnlyNamePipe,
    ProfilePipe,
    GetVideoPipe,
    GetNameSilletasPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
