import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { CustomizationfileService } from '../services/services';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'termsAndCondition'
})
export class TermsAndConditionPipe implements PipeTransform {

  private key_db: string;

  constructor(
    private _cf: CustomizationfileService,
    public db: AngularFireDatabase
  ) {
    this.key_db = this._cf.getKeyDb();
  }

  transform(uid: string): Observable<any> {
    return this.db.object(`/termsAndConditions/${this.key_db}/${uid}`)
    .valueChanges()
    .pipe(
      // tap((data) => console.log('TermsAndConditionPipe', data)),
      map((data: any) => ({tac: (!data) ? false : data.accept })),
      catchError((err) => {
        console.log('Error on TermsAndConditionPipe', err);
        return of({tac: false});
      }),
      // tap((data) => console.log('TermsAndConditionPipe', data))
    )
  }

}
