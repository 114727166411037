import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-calificacion-silletero-tabla-causal',
  templateUrl: './reporte-calificacion-silletero-tabla-causal.component.html',
  styleUrls: ['./reporte-calificacion-silletero-tabla-causal.component.scss'],
})
export class ReporteCalificacionSilleteroTablaCausalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
