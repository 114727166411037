import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { handlerArrayResult, handlerObjectResult } from '../helpers/model.helper';
import { firestore } from 'firebase/app';
import { HttpClient } from '@angular/common/http';

const TYPE_SILLETAS = '__typeSilleta'

@Injectable({
  providedIn: 'root'
})
export class SilleterosService {

  public collection = environment.production ? "silleteros" : "dev_silleteros";
  public minJudges = 3;
  private storageSubSillletaList = new BehaviorSubject<string>(localStorage.getItem(TYPE_SILLETAS));

  constructor(
    private http: HttpClient,
    public afs: AngularFirestore,
    public db: AngularFireDatabase,
  ) {
    console.log('Hello SchoolService Provider');
  }

  /**
   * 
   * @returns 
   */
  watchStorage(): Observable<any> {
    return this.storageSubSillletaList.asObservable();
  }

  /**
   * 
   * @param data 
   */
  setItem(data: any): void {
    localStorage.setItem(TYPE_SILLETAS, data);
    this.storageSubSillletaList.next(data);
  }

  /**
   * 
   */
  removeItem(): void {
    localStorage.removeItem(TYPE_SILLETAS);
    this.storageSubSillletaList.next(null);
  }


  /**
   * 
   * @param data 
   * @returns 
   */
  store(data) {
    return this.afs.collection(this.collection).add(data);
  }

  /**
   * 
   * @param qrCode 
   * @returns 
   */
  async checkIfQrCodeExists(qrCode: string) {
    const snapshot = await this.afs.collection(this.collection, ref => ref.where('qr', '==', qrCode)).get().toPromise();
    const result = await handlerArrayResult(snapshot);
    return (result.length > 0);
  }


  /**
   * 
   * @param docId 
   * @param data 
   */
  addObjectToArray(key: string, docId: string, data: any) {
    // Utiliza arrayUnion para agregar el nuevo item al array en Firestore
    return this.afs.collection(this.collection).doc(docId)
      .update({
        [key]: firestore.FieldValue.arrayUnion(data)
      })
  }

  removeObjectToArray(key: string, docId: string, index: any) {
    // Utiliza arrayUnion para agregar el nuevo item al array en Firestore
    return this.afs.collection(this.collection).doc(docId)
      .update({
        [key]: firestore.FieldValue.arrayRemove(index)
      })
  }




  /**
   * @dev   METODO PARA VALIDAR A LOS SILLETEROS
   * @param docId 
   * @param round 
   * @param stage
   * @returns 
   */
  async runQualificacion(docId: string, roundKey: string, stageKey: any) {

    try {
      const snapshot: any = await this.getById(docId);

      const isApproved = (snapshot[roundKey].length >= this.minJudges) ? snapshot[roundKey].every((row: any) => row.status == 'approved') : false;
      const isUndecided = (snapshot[roundKey].length >= this.minJudges) ? snapshot[roundKey].every((row: any) => row.status == 'undecided') : false;
      const isDiscard = (snapshot[roundKey].length >= this.minJudges) ? snapshot[roundKey].every((row: any) => row.status == 'discard') : false;


      console.log('isApproved', {
        isApproved,
        isUndecided,
        isDiscard
      });

      let status: any;
      let round: any;
      let stage: any;
      let orden = Date.now();

      /// @dev decide si pasa a la siguiente ronda o no
      if (isApproved) {
        status = 'approved';
        round = roundKey;
        stage = stageKey;
      } else if (isUndecided) {
        status = 'undecided';
        round = roundKey;
        stage = stageKey;
      } else if (isDiscard) {
        status = 'discard';
        round = roundKey;
        stage = stageKey;
      } else {
        round = snapshot.round;
        status = snapshot.status;
        stage = snapshot.stage;
      }

      return {
        [`orden${roundKey}`]: orden,
        status,
        round,
        stage
      };
    } catch (error) {
      console.log('error', error);
    }

  }



  // /**
  //  * @dev round 2
  //  * @param docId 
  //  * @returns 
  //  */
  // async _runQualificacion(docId: string) {
  //   const snapshot = await this.getById(docId);

  //   const { numberOfJudges = this.minJudges, qualification, } = snapshot;

  //   /**
  //    * TODO: válidar que siempre esten los 3 registros necesarios para poder actualizar
  //    * el estatus del documento
  //    */

  //   console.log('qualification', qualification);

  //   const isApproved = (qualification.length >= this.minJudges) ? qualification.every((row) => row.status == 'approved') : false;
  //   const isUndecided = (qualification.length >= this.minJudges) ? qualification.every((row) => row.status == 'undecided') : false;
  //   const isDiscard = (qualification.length >= this.minJudges) ? qualification.every((row) => row.status == 'discard') : false;


  //   let status: string;
  //   let stage: number
  //   let round: number;

  //   if (isApproved) {
  //     stage = 7;
  //     status = "approved"
  //     round = 1
  //   } else if (isUndecided) {
  //     stage = 6;
  //     status = "undecided";
  //     round = 0
  //   } else if (isDiscard) {
  //     stage = 5;
  //     status = "discard"
  //   } else {
  //     round = 0
  //     stage = snapshot.stage;
  //     status = snapshot.status;
  //   }

  //   return {
  //     status,
  //     stage,
  //     round

  //   };
  // }


  // /**
  //  * 
  //  */
  // async runQualificacionBySecondRound(docId: string) {
  //   const snapshot = await this.getById(docId);

  //   const { numberOfJudges = this.minJudges, qualificationByItem } = snapshot;

  //   /**
  //    * TODO: válidar que siempre esten los 3 registros necesarios para poder actualizar
  //    * el estatus del documento
  //    */

  //   const isApproved = (qualificationByItem.length >= this.minJudges) ? true : false;


  //   let status: string;
  //   let stage: number
  //   let round: number;
  //   if (isApproved) {
  //     stage = 8;
  //     round = 2
  //     status = "round2"
  //   } else {
  //     round = 1
  //     stage = snapshot.stage;
  //     status = snapshot.status;
  //   }

  //   return {
  //     round,
  //     status,
  //     stage
  //   };
  // }


  // async runQualificacionByThirdRound(docId: string) {
  //   const snapshot = await this.getById(docId);

  //   const { numberOfJudges = this.minJudges, qualificationByItem3Round } = snapshot;

  //   /**
  //    * TODO: válidar que siempre esten los 3 registros necesarios para poder actualizar
  //    * el estatus del documento
  //    */

  //   const isApproved = (qualificationByItem3Round.length >= this.minJudges) ? true : false;


  //   let status: string;
  //   let stage: number
  //   let round: number;
  //   if (isApproved) {
  //     stage = 10;
  //     round = 4
  //     status = "round3"
  //   } else {
  //     round = snapshot.round
  //     stage = snapshot.stage;
  //     status = snapshot.status;
  //   }

  //   return {
  //     round,
  //     status,
  //     stage
  //   };
  // }

  // /**
  //  * 
  //  * @param docId 
  //  * @returns 
  //  */
  // async runQualificacionByAbsoluteWinner(docId: string) {
  //   const snapshot = await this.getById(docId);

  //   const { numberOfJudges = this.minJudges, absoluteWinnerItems } = snapshot;

  //   /**
  //    * TODO: válidar que siempre esten los 3 registros necesarios para poder actualizar
  //    * el estatus del documento
  //    */

  //   const isApproved = (absoluteWinnerItems.length >= this.minJudges) ? true : false;


  //   let status: string;
  //   let stage: number

  //   if (isApproved) {
  //     stage = 12;
  //     status = "final"
  //   } else {
  //     stage = snapshot.stage;
  //     status = snapshot.status;
  //   }

  //   return {
  //     status,
  //     stage
  //   };
  // }


  /**
   * 
   * @param records 
   * @param key 
   * @returns 
   */
  async storeBatch(records: any[], key: string): Promise<void> {
    const batch = this.afs.firestore.batch();
    records.forEach((record) => {
      const ref = this.afs.firestore.collection(this.collection).doc(record[key].toString());
      batch.set(ref, record);
    });
    return await batch.commit();
  }


  /**
   * 
   * @param docId 
   * @param data 
   * @returns 
   */
  async update(docId: string, data: any) {
    console.log('update', { docId, data });
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  /**
  * 
  * @param status 
  * status: pending, approved, rejected, falsificado
  * @returns 
  */
  getUserCounters(stage: any) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('stage', '==', stage)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  getUserPendingCounter() {
    return this.getDynamicCounter([
      { field: 'stage', condition: '==', value: 0 },
      { field: 'status', condition: '==', value: 'pending' }
    ]);
  }

  /**
   * 
   * @param stage 
   * @returns 
   */
  getUserCountersTypeSilleta(stage: any, typeSilleta: any) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('stage', '==', stage)
        .where('typeSilleta', '==', typeSilleta)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  /**
  * 
  * @param stage 
  * @returns 
  */
  getUserCountersTypeSilletaTotal(typeSilleta: any) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('typeSilleta', '==', typeSilleta)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  getTotalGeneralSilleta() {
    return this.afs
      .collection(this.collection).valueChanges()
      .pipe(map(documents => documents.length));
  }


  /**
   * 
   * @returns 
   */
  getTotalSilleteros() {
    return this.afs
      .collection(this.collection).valueChanges()
  }

  /**
   * 
   * @param typeSilleta 
   * @param status 
   * @returns 
   */
  getUserCountersTypeSilletaTypeStatus(status: any, typeSilleta: any,) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('status', '==', status)
        .where('typeSilleta', '==', typeSilleta)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  /**
  * 
  * @param status 
  * @returns 
  */
  getUserCountersTypeSilletaStatus(status: any) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('status', '==', status)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  /**
   * 
   * @param typeSilleta 
   * @param round 
   * @returns 
   */
  getUserCountersTypeSilletaRoundSilleta(round: any, typeSilleta: any) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('round', '==', round)
        .where('typeSilleta', '==', typeSilleta)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }



  getUserCountersTypeSilletaRound(round: any,) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('round', '==', round)
      ).valueChanges()
      .pipe(map(documents => documents.length));
  }

  /**
   * 
   * @returns 
   */
  getUserCountersTotal() {
    return this.afs
      .collection(this.collection).valueChanges()
      .pipe(map(documents => documents.length));
  }

  /**
   * 
   * @param docId 
   * @param data 
   * @returns 
   */
  updateRealtime(docId: string, data: any) {
    return this.db.list(`${this.collection}/${docId}`).update(docId, data);
  }

  async set(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).set(data);
  }

  async getById(docId: string) {
    const snapshot = await this.afs
      .collection(this.collection)
      .doc(docId)
      .ref.get();
    return await handlerObjectResult(snapshot);
  }

  getByIdObservable(docId: string) {
    return this.afs
      .collection(this.collection)
      .doc(docId).valueChanges();
  }

  // Get pending documents records paginated
  async getPendingDocumentsWithLimit(limit: number = 50) {
    const snapshot = await this.afs
      .collection(this.collection, ref => ref
        .where('status', '==', 'pending')
        .orderBy('createdAt', 'asc')
        .limit(limit)
      )
      .ref.get();
    return await handlerObjectResult(snapshot);
  }

  getPendingDocumentsWithLimitObservable(limit: number = 50) {
    return this.afs
      .collection(this.collection, ref => ref
        .where('status', '==', 'pending')
        .orderBy('create_At', 'desc')
        .limit(limit)
      )
      .valueChanges();
  }


  getDynamic(where: any[] = [], opts: any = {}): Observable<any> {
    const { idField = "_id", orderBy = [] } = opts;

    return this.afs.collection(
      this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }

        for (const order of orderBy) { query = query.orderBy(order.field, order.order); }
        return query;
      }
    ).valueChanges({ idField });
  }

  getDynamicCounter(where: any[] = []): Observable<any> {
    return this.afs.collection(
      this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }
        return query;
      }
    ).valueChanges()
      .pipe(map(documents => documents.length));
  }



  getDynamicOnEvent(where: any[] = [], opts: any = {}): Observable<any> {
    const { idField = "_id", orderBy = [], limit = null } = opts;

    return this.afs
      .collection(
        this.collection,
        (ref) => {
          let query: Query = ref;
          for (const row of where) { query = query.where(row.field, row.condition, row.value); }

          for (const order of orderBy) { query = query.orderBy(order.field, order.order); }

          if (limit) { query = query.limit(limit); }

          return query;
        }
      )
      .valueChanges({ idField });
  }

  async getDynamicOnEventToPromise(where: any[] = [], opts: any = {}): Promise<any> {
    const { idField = "_id", orderBy = [], limit = null } = opts;
    const snapshot = await this.afs
      .collection(
        this.collection,
        (ref) => {
          let query: Query = ref;
          for (const row of where) { query = query.where(row.field, row.condition, row.value); }

          for (const order of orderBy) { query = query.orderBy(order.field, order.order); }

          if (limit) { query = query.limit(limit); }

          return query;
        }
      ).get().toPromise();

    return await handlerArrayResult(snapshot, { idField });
  }


  /**
   * 
   * @param firebaseUrl 
   */
  downloadFile(firebaseUrl: string) {
    this.http.get(firebaseUrl, { responseType: 'blob', observe: 'response' }).subscribe(response => {
      const blob = response.body!;
      const contentDisposition = response.headers.get('content-disposition') || '';
      const filename = (contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) || [null, 'content'])[1];

      return this.triggerDownload(blob, filename);
    });
  }


  triggerDownload(blob: Blob, filename: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);

    a.href = objectUrl;
    a.download = Date.now() + '-' + filename;
    a.click();
    return URL.revokeObjectURL(objectUrl);
  }
}
