import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyName'
})
export class OnlyNamePipe implements PipeTransform {

  /**
   * 
   * @param name 
   * @returns 
   */
  transform(name: any): any {
    if (!name) return;
    const _name = name.split(' ');
    return _name[0];
  }

}
