import { Injectable, NgZone } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, Observable } from "rxjs";
import { Platform } from "@ionic/angular";
import * as firebase from "firebase/app";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from "@angular/fire/database";
import { Router } from "@angular/router";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
// import AuthProvider = firebase.auth.AuthProvider;

const TOKEN_KEY = "auth-token";
const ROL_KEY = "rol-key";
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  public authenticationState = new BehaviorSubject(false);
  public user: firebase.User;
  public token;
  public role: any;

  constructor(
    private storage: Storage,
    public db: AngularFireDatabase,
    private plt: Platform,
    public afAuth: AngularFireAuth,
    public router: Router,
    public _cf: CustomizationfileService,
    private zone: NgZone,
    private http: HttpClient,
    public afs: AngularFirestore,
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  login() {
    return new Promise(async resolve => {
      this.afAuth.authState.subscribe(async user => {
        if (user) {
          this.user = user;
          this.storage.set(TOKEN_KEY, user.uid).then(async () => {
            this.token = user.uid;
            window.localStorage.setItem("auth", "true");
            /**
             * TODO: pagina inicial
             */
            this.router.navigate(["/silleteros/dashboard"]);
            resolve(true);
          });
        } else {
          window.localStorage.setItem("auth", "false");
          resolve(true);
        }
      });
    });
  }

  /**
 * Crear documento y almacenar para la colección de usuarios
 * @param params
 */
  async buildAndStoreUserDoc(params: any) {
    const doc = {
      uid: params.uid,
      _language: "English",
      rol: 0,
      status: "incomplete_profile",
      stageName: false,
      avatar: params.avatar,
      name: params.name,
      prefijo: null,
      phone: null,
      identificationType: params.identificationType || 'cedula',
      identification: params.identification,
      email: params.email
    };

    await this.afs.collection("users").doc(params.uid).set(doc);
    return doc;
  }


  /**
* Crear documento para la colección de perfiles
* @param params
*/
  async buildAndStoreProfileDoc(params: any) {
    const doc = {
      uid: params.uid,
      email: params.email,
      name: params.name.toLowerCase(),
      surnames: params.surnames.toLowerCase(),
      idType: "incomplete_profile",
      gender: null,
      birthdate: null,
      identificationType: params.identificationType || 'cedula',
      identificationNumber: params.identification,
      school: null,
      tShirtSize: null,
      bio: null,
      celular: null,
      cityOfBirth: null,
      countryOfBirth: null,
      countryOfResidence: null,
      facebook: null,
      instagram: null,
      prefijo: null,
      phone: null,
      stageName: null,
      stateOfBirth: null,
      stateOfResidence: null,
    };

    await this.afs.collection("profile").doc(params.uid).set(doc);
    return doc;
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  isAuthenticated() {
    return window.localStorage.getItem("auth") === "true";
  }

  async setRol(rol) {
    console.log(rol);
    return new Promise(async resolve => {
      await this.storage.set(ROL_KEY, rol).then(async () => {
        resolve(true);
      });
    });
  }

  async setToken(token) {
    return new Promise(async resolve => {
      await this.storage.set(TOKEN_KEY, token).then(async () => {
        resolve(true);
      });
    });
  }

  checkToken() {
    return localStorage.getItem("tokenPush");
  }

  async checkRol() {
    await this.storage.get(ROL_KEY).then(res => {
      console.log(res);
      return res;
    });
  }

  getProfile(uid) {
    return new Promise(async resolve => {
      const url = `/users/${uid}/profile`;
      this.db
        .object(url)
        .valueChanges()
        .subscribe(async profile => {
          resolve(profile);
        });
    });
  }

  getAvatar(uid) {
    return new Promise(async resolve => {
      const url = `/users/${uid}/avatar`;
      this.db
        .object(url)
        .valueChanges()
        .subscribe(async img => {
          resolve(img);
        });
    });
  }

  signInWithEmail(credentials) {
    return this.afAuth.auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  sendPasswordResetEmail(emailAddress: string) {
    return this.afAuth.auth.sendPasswordResetEmail(emailAddress)
  }

  /**
   * Actualizar el correo electronico del usuario
   * @name updateUserEmail
   * @param uid               ID del usuario
   * @param newEmail          Nuevo correo electronico
   * @returns 
   */
  async updateUserEmail(uid: any, newEmail: string) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${environment.urlrootFunctions}/manage_users/updateEmail`;
        this.http.post(url, { uid, email: newEmail })
          .subscribe((res: any) => {
            return resolve(res);
          }, (err) => reject(err))
      } catch (err) {
        console.log('Error on AuthenticationService.updateUserEmail', err);
        return reject(err)
      }
    });
  }

  async updateEventUserEmail(key_db: string, uid: any, newEmail: string) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${environment.urlrootFunctions}/v3/admin/update-user-email`;
        this.http.post(url, { key_db, uid, email: newEmail })
          .subscribe((res: any) => {
            return resolve(res);
          }, (err) => reject(err))
      } catch (err) {
        console.log('Error on AuthenticationService.updateEventUserEmail', err);
        return reject(err)
      }
    });
  }

  checkProfile() {
    let profile;
    this.storage.get("profile").then(res => {
      profile = res;
    });
    return profile;
  }

  async isAdminPromise(uid: string) {
    try {
      if (!uid) return false;
      const snapshot = await this.db.object(`/users/${uid}/super-admin`).valueChanges().pipe(take(1)).toPromise();
      return (!snapshot) ? false : snapshot;

    } catch (err) {
      console.log('Error on AuthService.isAdminPromise', err);
      return false;
    }
  }

  isAdmin(uid) {
    return new Promise(resolve => {

      const superAdmin = `/users/${uid}/super-admin`
      this.db.object(superAdmin).valueChanges().subscribe(superAdmin => {
        console.log("superAdmin", superAdmin)

        if (!superAdmin) {
          const url = `users/${uid}/rol`;
          console.log(url)
          // Verifamos su rol de usuarios
          this.db
            .object(url)
            .valueChanges()
            .subscribe(roluser => {
              if (roluser) {
                console.log("rolevent", roluser)
                const urlEve = `eventRoles/${this._cf.getKeyDb()}/${roluser}/${uid}`;
                // Verifamos su rol en el evento
                this.db
                  .object(urlEve)
                  .valueChanges()
                  .subscribe(rolevent => {
                    console.log("rolevent", rolevent)
                    if (roluser == rolevent) {
                      resolve(rolevent);
                    } else {
                      resolve(0);
                    }
                  });
              } else {
                resolve(0);
              }
            });
        } else {
          resolve(1);
        }

      })


    });
  }

  logout() {

    localStorage.clear();
    this.afAuth.auth.signOut();
    this.router.navigate(["login"]);


  }
}

/**
 * Validat si usuario registrado a través de un email
 *
 * @param service 
 * @returns 
 */
export function checkIfEmailDoesExist(service: AuthenticationService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return service.afs.collection('users', (ref) => ref.where('email', '==', `${control.value}`.trim()).limit(1)).get()
      .pipe(
        // tap((result) => console.log(result) ),
        map((data) => {
          // console.log({data});
          return (data.empty) ? null : { emailStored: true };
        })
      );
  }
}