import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getVideo'
})
export class GetVideoPipe implements PipeTransform {

  /**
   * 
   * @param videoBlob 
   * @returns 
   */
  transform(videoBlob: any): any {
    return URL.createObjectURL(videoBlob);
  }
}

