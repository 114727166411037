import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { CustomizationfileService } from '../services/services';

@Pipe({
  name: 'isResult'
})
export class IsResultPipe implements PipeTransform {

  constructor(
    public _cf: CustomizationfileService,
    public db: AngularFireDatabase) { }

  async transform(day, code, type): Promise<any> {
    const isShow = await this.getHiddenResults(day, code, type);
    return isShow;
  }


  /**
   * 
   * @param day 
   * @param code 
   * @param type 
   * @returns 
   */
  getHiddenResults(day: string, code: string, type = 'globalQualificationRecord') {
    return new Promise((resolve) => {
      return this.db.object(`/globalQualificationRecord/${this._cf.getKeyDb()}/${day}/${code}/participation`)
        .valueChanges()
        .subscribe((data: any) => {
          if (data == null) { return resolve(false) }
          resolve(data);
        });
    })
  }

}
