import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reporte-calificacion-silletero-tabla-criterios',
  templateUrl: './reporte-calificacion-silletero-tabla-criterios.component.html',
  styleUrls: ['./reporte-calificacion-silletero-tabla-criterios.component.scss'],
})
export class ReporteCalificacionSilleteroTablaCriteriosComponent implements OnInit, OnChanges {

  @Input() tipoSilleta: any = 'na';
  @Input() criteriosDeEvaluacion: any;

  public tabulacion = {
    na: {
      items: []
    },
    silleta_artistica: {
      items: [
        {label: "Mensaje", pg: 15, idx: 1},
        {label: "Emblema", pg: 15, idx: 2},
        {label: "Estética", pg: 15, idx: 3},
        {label: "Creatividad", pg: 15, idx: 0},
        {label: "Vestuario del silletero en general", pg: 10, idx: 4},
        {label: "Criterios del jurado", pg: 10, idx: 5},
        {label: "Cumplimiento de las normas", pg: 20, idx: 6},
      ]
    },
    silleta_comercial: {
      items: [
        {label: "Proyección de la imagen", pg: 40, idx: 0},
        {label: "Estética", pg: 10, idx: 1},
        {label: "Creatividad", pg: 10, idx: 2},
        {label: "Vestuario del silletero en general", pg: 10, idx: 3},
        {label: "Criterios del jurado", pg: 10, idx: 4},
        {label: "Cumplimiento de las normas", pg: 20, idx: 4},
      ]
    },
    silleta_emblematica: {
      items: [
        {label: "Mensaje", pg: 15, idx: 0},
        {label: "Emblema", pg: 15, idx: 0},
        {label: "Estética", pg: 15, idx: 0},
        {label: "Creatividad", pg: 15, idx: 0},
        {label: "Vestuario del silletero en general", pg: 10, idx: 0},
        {label: "Criterios del jurado", pg: 10, idx: 0},
        {label: "Cumplimiento de las normas", pg: 20, idx: 0},
      ]
    },
    silleta_monumental: {
      items: [
        {label: "Creatividad en la forma de la silleta", pg: 15, idx: 0},
        {label: "Variedad de Flores y Follajes (Restricciones)", pg: 30, idx: 1},
        {label: "Estética", pg: 15, idx: 2},
        {label: "Vestuario del Silletero en general", pg: 10, idx: 3},
        {label: "Criterios del jurado", pg: 10, idx: 4},
        {label: "Cumplimiento de las normas ", pg: 20, idx: 5},
      ]
    },
    silleta_pionera: {
      items: [] // no tiene criterios de evaluacion
    },
    silleta_tradicional: {
      items: [
        {label: "Variedad de la flor y tipo de flor (Restricciones)", pg: 30, idx: 0},
        {label: "Estética", pg: 30, idx: 1},
        {label: "Vestuario del Silletero en general", pg: 10, idx: 2},
        {label: "Criterios del jurado", pg: 10, idx: 3},
        {label: "Cumplimiento de las normas", pg: 20, idx: 4},
      ]
    },
    silleta_junior: {
      items: [] // no tiene criterios de evaluacion
    },
    silleta_infantil: {
      items: [] // no tiene criterios de evaluacion
    },
  };

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { criteriosDeEvaluacion, tipoSilleta } = changes;

    if (tipoSilleta && tipoSilleta.currentValue) {
      this.tipoSilleta = tipoSilleta.currentValue;
    }

    if (criteriosDeEvaluacion && criteriosDeEvaluacion.currentValue) {
      console.log('criteriosDeEvaluacion', criteriosDeEvaluacion.currentValue);
      this.criteriosDeEvaluacion = criteriosDeEvaluacion.currentValue;
    }
  }

}
