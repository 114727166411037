import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { SilleterosService } from 'src/app/services/silleteros.service';
import { FullScreenPhotoComponent } from '../full-screen-photo/full-screen-photo.component';


@Component({
  selector: 'app-validate-multimedia',
  templateUrl: './validate-multimedia.component.html',
  styleUrls: ['./validate-multimedia.component.scss'],
})
export class ValidateMultimediaComponent implements OnInit {

  public item: any;
  stage: any;
  images: string[] = [];
  segment = 'ratings';
  qualificationByTotal: any;
  constructor(

    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log('item', this.item);
    console.log('stage', this.stage);
    if (this.stage == 1) {
      this.qualificationByTotal = this.item.round1;
     }
    else if (this.stage == 2) {
      this.qualificationByTotal = this.item.round2;
    }
    else if (this.stage == 3) {
      this.qualificationByTotal = this.item.round3;
    }
    else if (this.stage == 4) {
      this.qualificationByTotal = this.item.round4;
    }
  }

  /**
   * 
   * @returns 
   */
  async cancel() {
    this.images = []
    this.closeModal();
  }

  /**
 * 
 * @param item 
 * @returns 
 */
  async fullScreenPhoto(item) {
    console.log('item', item);
    const modal = await this.modalCtrl.create({
      component: FullScreenPhotoComponent,
      cssClass: 'my-custom-class',
      componentProps: { item: item },
      swipeToClose: true,
      presentingElement: await this.modalCtrl.getTop() // Get the top-most ion-modal
    });
    return await modal.present();
  }

  /**
   * 
   * @param index 
   */
  removeImage(index) {
    this.images.splice(index, 1);
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }


}
