import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Component
import { SidebarMenuComponent } from "./components/sidebar-menu/sidebar-menu.component";
import { ExpandableComponent } from "./components/expandable/expandable.component";

// Pipes
import { BackstagePipe } from '../pipes/backstage/backstage.pipe';
import { NgforPipe } from "../pipes/Ngfor/ngfor.pipe";
import { KeysPipe } from "../pipes/keys/keys.pipe";
import { ValidProfilePipe } from '../pipes/validProfile/valid-profile.pipe';
import { SortValidPipe } from '../pipes/sortValid/sort-valid.pipe';
import { SortPhotoYCityPipe } from '../pipes/sortValid/sort-photo-ycity.pipe';
import { SortBackstagePipe } from '../pipes/sortBackstage/sort-backstage.pipe';
import { BackstageShowPipe } from '../pipes/backstage-show/backstage-show.pipe';
import { CallBackstagePipe } from '../pipes/call-backstage/call-backstage.pipe';
import { FileSizeFormatPipe } from "../pipes/fileSize/file-size.pipe";
import { FormatFileSizePipe } from "../pipes/formatFileSize/format-file-size.pipe";


import { SkeletonComponent } from "./components/skeleton/skeleton.component";
import { ScheduleComponent } from './components/qr-modal/schedule/schedule.component'
import { ParticipantsComponent } from "./components/qr-modal/participants/participants.component";
import { InformationComponent } from "./components/information/information.component";
import { CustomEmailNotificationFormComponent } from "./components/custom-email-notification-form/custom-email-notification-form.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { PipesModule } from "../pipes/pipes.module";
import { AdminFormUpdateUserEmailComponent } from "./components/admin-form-update-user-email/admin-form-update-user-email.component";
import { CustomInputFileComponent } from "./components/custom-input-file/custom-input-file.component";
import { CustomInputPhotoComponent } from "./components/custom-input-photo/custom-input-photo.component";
import { CustomInputVideoComponent } from "./components/custom-input-video/custom-input-video.component";
import { FullScreenPhotoComponent } from "./components/full-screen-photo/full-screen-photo.component";
import { QrcodeComponent } from "./components/qrcode/qrcode.component";
import { ValidateMultimediaComponent } from "./components/validate-multimedia/validate-multimedia.component";
import { SignatureSilleterosComponent } from "./components/signature-silleteros/signature-silleteros.component";
import { ReporteCalificacionSilleteroComponent } from "./components/reporte-calificacion-silletero/reporte-calificacion-silletero.component";
import { PopoverComponent } from "./components/popover/popover.component";
import { ReporteCalificacionSilleteroTablaCriteriosComponent } from "./components/reporte-calificacion-silletero-tabla-criterios/reporte-calificacion-silletero-tabla-criterios.component";
import { ReporteCalificacionSilleteroTablaInformacionComponent } from "./components/reporte-calificacion-silletero-tabla-informacion/reporte-calificacion-silletero-tabla-informacion.component";
import { ReporteCalificacionSilleteroTablaSancionesComponent } from "./components/reporte-calificacion-silletero-tabla-sanciones/reporte-calificacion-silletero-tabla-sanciones.component";
import { ReporteCalificacionSilleteroTablaCausalComponent } from "./components/reporte-calificacion-silletero-tabla-causal/reporte-calificacion-silletero-tabla-causal.component";



@NgModule({
    imports: [
        IonicModule,
        RouterModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        AngularEditorModule,
        ZXingScannerModule
    ],
    declarations: [
        SidebarMenuComponent,
        ExpandableComponent,
        BackstagePipe,
        ValidProfilePipe,
        NgforPipe,
        KeysPipe,
        SortValidPipe,
        SortPhotoYCityPipe,
        SortBackstagePipe,
        BackstageShowPipe,
        CallBackstagePipe,
        FileSizeFormatPipe,
        FormatFileSizePipe,
        ScheduleComponent,
        ParticipantsComponent,
        SkeletonComponent,
        InformationComponent,
        CustomEmailNotificationFormComponent,
        AdminFormUpdateUserEmailComponent,
        CustomInputFileComponent,
        CustomInputVideoComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        ValidateMultimediaComponent,
        SignatureSilleterosComponent,
        ReporteCalificacionSilleteroComponent,
        PopoverComponent,
        ReporteCalificacionSilleteroTablaCriteriosComponent,
        ReporteCalificacionSilleteroTablaInformacionComponent,
        ReporteCalificacionSilleteroTablaSancionesComponent,
        ReporteCalificacionSilleteroTablaCausalComponent
    ],
    exports: [
        SidebarMenuComponent,
        ExpandableComponent,
        ScheduleComponent,
        ParticipantsComponent,
        SkeletonComponent,
        InformationComponent,
        BackstagePipe,
        ValidProfilePipe,
        NgforPipe,
        KeysPipe,
        SortValidPipe,
        SortPhotoYCityPipe,
        SortBackstagePipe,
        BackstageShowPipe,
        FileSizeFormatPipe,
        CallBackstagePipe,
        FormatFileSizePipe,
        CustomEmailNotificationFormComponent,
        AdminFormUpdateUserEmailComponent,
        CustomInputVideoComponent,
        CustomInputFileComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        ValidateMultimediaComponent,
        SignatureSilleterosComponent,
        ReporteCalificacionSilleteroComponent,
        PopoverComponent,
        ReporteCalificacionSilleteroTablaCriteriosComponent,
        ReporteCalificacionSilleteroTablaInformacionComponent,
        ReporteCalificacionSilleteroTablaSancionesComponent,
        ReporteCalificacionSilleteroTablaCausalComponent
    ],
    entryComponents: [
        InformationComponent,
        CustomEmailNotificationFormComponent,
        AdminFormUpdateUserEmailComponent,
        CustomInputVideoComponent,
        CustomInputFileComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        ValidateMultimediaComponent,
        SignatureSilleterosComponent,
        ReporteCalificacionSilleteroComponent,
        PopoverComponent,
        ReporteCalificacionSilleteroTablaCriteriosComponent,
        ReporteCalificacionSilleteroTablaInformacionComponent,
        ReporteCalificacionSilleteroTablaSancionesComponent,
        ReporteCalificacionSilleteroTablaCausalComponent
    ]
})
export class SharedModule { }
