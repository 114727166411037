import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireStorage } from "@angular/fire/storage";

import { HttpClient } from "@angular/common/http";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { InformationComponent } from "../../shared/components/information/information.component";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage";
import {
  ModalController,
  LoadingController,
  ToastController,
  AlertController,
  PopoverController,
} from "@ionic/angular";
import { environment } from "../../../environments/environment";
import { NgforPipe } from "../../pipes/Ngfor/ngfor.pipe";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { PopoverComponent } from "src/app/shared/components/popover/popover.component";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public key = "";
  public urlDB = environment.urlDB;
  public urlrootFunctions = environment.urlrootFunctions;
  public _production = environment._production;
  loading: any;


  listVereda = [
    {
      "id": 1,
      "name": "BARRO BLANCO"
    },
    {
      "id": 9,
      "name": "EL CERRO"
    },
    {
      "id": 5,
      "name": "EL LLANO"
    },
    {
      "id": 2,
      "name": "EL PLACER"
    },
    {
      "id": 14,
      "name": "EL PLACER"
    },
    {
      "id": 13,
      "name": "EL PLAN"
    },
    {
      "id": 17,
      "name": "EL PLAN"
    },
    {
      "id": 12,
      "name": "EL PORVENIR"
    },
    {
      "id": 6,
      "name": "EL ROSARIO"
    },
    {
      "id": 7,
      "name": "LA PALMA"
    },
    {
      "id": 11,
      "name": "MAZO"
    },
    {
      "id": 18,
      "name": "MEDIA LUNA"
    },
    {
      "id": 15,
      "name": "PANTANILLO"
    },
    {
      "id": 16,
      "name": "PARTE CENTRAL"
    },
    {
      "id": 10,
      "name": "PERICO"
    },
    {
      "id": 4,
      "name": "PIEDRA GORDA"
    },
    {
      "id": 3,
      "name": "SAN IGNACIO"
    },
    {
      "id": 8,
      "name": "SAN MIGUEL"
    }
  ]

  public rolList: any = [
    { id: "16", name: "Check In" },
    { id: "7", name: "Backstage" },
    { id: "18", name: "Judges Verifier" },
    { id: "8", name: "MC" },
    { id: "11", name: "DJ" },
    { id: "9", name: "Screen" },
    { id: "0", name: "Competidor" },
    { id: "1", name: "Administrator" },
    { id: "2", name: "Acreditador" },
    { id: "3", name: "judges" },
    { id: "4", name: "Caja" },
    { id: "5", name: "Organizador Evento" },
    { id: "6", name: "Check Music" },
    { id: "12", name: "Support Tech" },
    { id: "13", name: "Report" },
    { id: "14", name: "Transaccion" },
    { id: "15", name: "Franchisee" },
    { id: "17", name: "Photo" },
    { id: "19", name: "Comunicador" },
    { id: "20", name: "academy" },
  ];


  constructor(
    public popoverController: PopoverController,
    public db: AngularFireDatabase,
    private http: HttpClient,
    public _cf: CustomizationfileService,
    public translate: TranslateService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private alertController: AlertController,
    public modalController: ModalController,
    private storage: Storage,
    private storageAf: AngularFireStorage
  ) {
    this.key = this._cf.getKeyDb();
  }

  getParameterByName(name, url?) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  transformarString(string) {
    if (!string) {
      return null;
    }
    const number = string.toString();
    return number.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "").toLowerCase()
  }

  // getTypeSilleta() {
  //   return this.typeSilleta
  // }

  getListVereda() {
    return this.listVereda
  }

  /**
   * 
   * @param ev 
   */
  async presentPopover(text: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: text
      },
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


  /**
   * 
   * @param message 
   * @returns 
   */
  async alertQuestion(message) {
    const alert = await this.alertController.create({
      header: "CILIK",
      message: message,
      backdropDismiss: false,
      animated: true,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Aceptar",
          role: "accept",
        }
      ]

    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    return role;
  }


  /**
   * 
   * @param message 
   * @returns 
   */
  async alertPromt(message) {
    const alert = await this.alertController.create({
      header: message,
      inputs: [
        {
          name: 'text',
          type: 'text',
          placeholder: 'Observaciones',
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    await alert.present();

    await alert.present();

    const role = await alert.onDidDismiss();

    return role.data.values.text;
  }


  /**
   * @dev eliminar duplicados de un array || object remove duplicates
   * @param array 
   * @param propiedad 
   * @returns 
   */
  removeDuplicates(array, propiedad) {
    return array.filter((elemento, indice, self) =>
      indice === self.findIndex((e) => e[propiedad] === elemento[propiedad])
    )
  }

  /**
   * @dev ordenar array por nombre
   * @param array 
   * @param key 
   * @returns 
   */
  sortFornName(array, key) {
    return array.sort((x, y) => {
      if (x[key] < y[key]) {
        return -1;
      }
      if (x[key] > y[key]) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * 
   * @param value 
   * @returns 
   */
  validProperty(value) {
    return value !== undefined ? value : null
  }

  /**
   * 
   * @param str 
   * @param delimiter 
   * @returns 
   */
  dedupe(str, delimiter) {
    if (!str) return;
    const vcf = str
      .split(delimiter || " ")
      .reverse()
      .filter((e, i, arr) => {
        return arr.indexOf(e, i + 1) === -1;
      })
      .reverse();
    return vcf.toString().replace(/,/g, " ");
  }

  downloadFile(url) {
    return this.http.get(url, { responseType: "arraybuffer" });
  }

  getUrlFirebaseStorage(url_gs) {
    return this.storageAf.storage.refFromURL(url_gs).getDownloadURL();
  }

  getKeyFinal(data) {
    if (data.participants.hasOwnProperty("pKey")) {
      return data.participants.pKey;
    } else if (data.participants.hasOwnProperty("key")) {
      return data.participants.key;
    } else if (data.participants.hasOwnProperty("keyStatus")) {
      return data.participants.keyStatus;
    } else if (data.hasOwnProperty("pKey")) {
      return data.pKey;
    } else if (data.hasOwnProperty("key")) {
      return data.key;
    } else if (data.hasOwnProperty("keyStatus")) {
      return data.keyStatus;
    } else {
      this.presentAlert("ERROR getKeyFinal");
    }
  }

  public async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: "top",
      duration: 3000,
    });
    toast.present();
  }

  async presentAlert(text) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      animated: true,
      cssClass: "alertLogCss",
      header: "CILIK",
      subHeader: text,
      buttons: ["OK"],
    });

    await alert.present();
  }

  public async presentLoading(text?) {
    this.loading = await this.loadingController.create({
      message: "por favor espera...",
    });
    return this.loading.present();
  }

  dismissLoading() {
    if (!this.loading) return;
    return this.loading.dismiss();
  }

  getSchoolRecord() {
    return this.db
      .list("schoolRecord", (ref) => ref.orderByChild("public").equalTo(true))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as any),
          }))
        )
      );
  }

  sortBoolean(array, key, is) {
    return array.sort((x, y) => {
      if (is) {
        // true values first
        return x[key] === y[key] ? 0 : x ? -1 : 1;
      } else {
        // false values first
        return x[key] === y[key] ? 0 : x ? 1 : -1;
      }
    });
  }

  getDay() {
    return new Promise(async (resolve) => {
      this.db
        .object(`/competitionday/${this._cf.getKeyDb()}`)
        .valueChanges()
        .subscribe((data: any) => {
          if (data) {
            resolve(new NgforPipe().transform(data));
          }
        });
    });
  }

  getStage() {
    return new Promise(async (resolve) => {
      this.db
        .object(`/stage_enabled/${this._cf.getKeyDb()}`)
        .valueChanges()
        .subscribe((data: any) => {
          if (data) {
            resolve(data);
          }
        });
    });
  }

  getTranslate(value) {
    return new Promise((resolve) => {
      this.translate.get([value]).subscribe((translations) => {
        resolve(translations);
      });
    });
  }

  financial(x) {
    if (x) {
      return Number.parseFloat(x).toFixed(2);
    }
  }

  getCalendar(day) {
    return new Promise(async (resolve) => {
      this.storage.get(day).then((val) => {
        resolve(val);
      });
    });
  }

  removeCalendar(day) {
    this.storage.remove(day);
  }

  removeCommas(data) {
    if (data) {
      return data.replace(/,/g, " & ");
    } else {
      return "";
    }
  }

  getImg(array, size) {
    if (array && size) {
      for (let index = 0; index < array.length; index++) {
        if (array[index].name == size) {
          return array[index].img;
        }
      }
    }
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  async transformArray(data) {
    return new Promise(async (resolve) => {
      if (!data || data == null || data == undefined) { return [] }
      let listParticipants = [];
      await data.map(async (x: any) => {
        const participants = this.getKey(x.categories);

        /// @dev le asigno la key a cada participante y status
        const _participants = await this.sortCountry(participants);

        /// @dev asigno el status a cada participante
        const participants_ = await this.sortCountry(_participants);

        const result = {
          info: x.info,
          categoryStages: x.categoryStages,
          participants: participants_
        };
        listParticipants.push(result);
      });
      console.log("listParticipants", listParticipants);
      resolve(listParticipants)
    });


  }

  /**
  *  @dev no tocar
  * @param data 
  * @returns 
  */
  async transformArray3(data) {
    return new Promise(async (resolve) => {
      if (!data) { resolve([]) }
      const listParticipants = [];
      await data.map(async (x) => {

        /// @dev le asigno la key a cada participante y status
        const participants = this.getKey3(x.categories);

        const result = {
          break: x.categoryStages != undefined ? false : true,
          info: x.info,
          categoryStages: x.categoryStages,
          participants: participants,
        };
        listParticipants.push(result);
      });
      // console.log("listParticipants", listParticipants);
      resolve(listParticipants);
    });
  }

  /**
 * @dev no tocar
 * @param data 
 * @returns 
 */
  getKey3(data) {
    if (!data) { return [] }

    /// @dev le asigno la key a cada participante
    const result = [];
    Object.keys(data).forEach((i) => {
      if (data[i].nameEvent && data[i].participants) {
        const rf = Object.assign(data[i], { key_: i, currentPosition: data[i]['currentPosition'] || 0 });
        result.push(rf);
      }
    });


    /**
     * TODO: filtrar por status 13 que estan eliminados y 11 que estan descalificados
     */
    /// @dev filtrar por status 13 que estan eliminados
    const result2 = result.filter((x) => {
      // console.log("x", x.statusScreen);
      return x.statusScreen !== 11 && x.statusScreen !== 13;
    }).sort((a, b) => {
      if (a.currentPosition !== 0 && !a.currentPosition) {
        // Si currentPosition no existe o no es igual a 0 en 'a'
        return a.members.localeCompare(b.members); // Ordenar por 'members'
      } else if (b.currentPosition !== 0 && !b.currentPosition) {
        // Si currentPosition no existe o no es igual a 0 en 'b'
        return b.members.localeCompare(a.members); // Ordenar por 'members' en orden inverso
      } else {
        return b.currentPosition - a.currentPosition; // Ordenar por 'currentPosition'
      }
    })
    return result2;
  }




  /**
   * @description transformArray2
   */
  async transformArray2(data) {
    return new Promise(async (resolve) => {
      if (!data) { resolve([]) }
      let listParticipants = [];

      await data.map(async (x) => {
        let participants = this.getKey2(x.categories, x.info, x.categoryStages);
        // let _participants = await this.sortCountry(participants);
        // let participants_ = await this.sortCountry(_participants);
        // let result = {
        //   // info: x.info,
        //   // categoryStages: x.categoryStages,
        //   participants: Obj,
        // };
        listParticipants.push(Object.assign({}, participants));
      });

      resolve(listParticipants)

    });

  }

  /**
   * 
   * @param data 
   * @returns 
   */
  getKey2(data, info, categoryStages) {
    if (data) {
      let result = [];
      Object.keys(data).forEach((i) => {
        if (data[i].nameEvent && data[i].participants) {
          let rf = Object.assign(data[i], { key_: i });
          result.push(Object.assign({}, rf, info, categoryStages));
        }
      });

      return result;
    }
  }

  getKey(data) {
    if (data) {
      let result = [];
      Object.keys(data).forEach((i) => {
        if (data[i].nameEvent && data[i].participants) {
          let rf = Object.assign(data[i], { key_: i });
          result.push(rf);
        }
      });

      return result;
    }
  }

  now() {
    let d = new Date();
    let h = this.addZero(d.getHours());
    let m = this.addZero(d.getMinutes());
    return this.convertHoursAndMinutes(h, m);
  }

  convertHoursAndMinutes(hours: number, minutes: number) {
    return hours * 60 + minutes;
  }

  clearCode(data) {
    return data.toLowerCase().replace(/ /g, "");
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  getlastTime(day) {
    return new Promise(async (resolve) => {
      this.storage.get(day).then((val) => {
        resolve(val);
      });
    });
  }

  setCalendar(data, day) {
    // set a key/value
    this.storage.set(day, data);
  }

  setlastTime(data, day) {
    this.storage.set(day, data);
  }

  sortCountry(data) {
    if (!data) return;
    return data.sort((x, y) => {
      return x.nameEvent.countryRating === y.nameEvent.countryRating
        ? 0
        : x.nameEvent.countryRating
          ? 1
          : -1;
    });
  }

  getDayName(day) {
    // for (let i = 0; i < this._persistence.day.length; i++) {
    //   if (day == this._persistence.day[i].value) {
    //     // this.day_ = this._persistence.day[i].name;
    //     return this._persistence.day[i].name
    //   }
    // }
  }

  getDate(): string {
    const dt = new Date();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    const year = dt.getFullYear();
    return month + "-" + day + "-" + year;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  sortHour(data) {
    if (!data) { return }
    const value = data
      .filter((x) => {
        return x.info !== undefined;
      }).sort((a, b) => {
        // console.log("a.info.startTime", a.info.startTime);
        if (a.info.startTime) {
          let x = a.info.startTime;
          let y = b.info.startTime;
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }
      });
    return value;

  }

  getHora(i, s) {
    let suma = i * 3 + s;
    return this.convertMinsToHrsMins(suma);
    // return suma;
  }

  getName(data) {
    if (!data) return;
    let u = [];
    data.forEach((elem) => {
      const name = `${elem.name} ${elem.surnames}`;
      u.push(name);
    });
    return u.toString().replace(/,/g, " & ");
  }

  async getInfo(user, category, hour) {
    if (user) {
      let result = {
        user: user,
        category: category,
        hour: hour,
      };
      this.modalController
        .create({
          component: InformationComponent,
          componentProps: { details: result },
        })
        .then((modal) => {
          modal.present();
        });
    }
  }

  convertMinsToHrsMins(mins) {
    if (!mins) return;
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  almostdisqualified(i, tp, status, isEnabled) {
    let horaactual = isEnabled;
    let horapresentacion = i * 4 + tp;
    if (status == 7) {
      return 7;
    }
    if (status !== 0) {
      return status;
    } else {
      let hEliminacion = horapresentacion - 30;
      let hPenalizacion = horapresentacion - 45;
      if (horaactual > hEliminacion) {
        return 6;
      } else if (horaactual > hPenalizacion && hPenalizacion < hEliminacion) {
        return 5;
      } else {
        return 0;
      }
    }
  }

  getStarted(mins) {
    if (!mins) return;
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getFinalHour(mins) {
    if (!mins) return;
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getCountryOfResidence() {
    return this.http.get("../../assets/country-state-city/country.json");
  }

  getCountryOfResidenceObservable() {
    return this.getCountryOfResidence()
      .pipe(
        map((data: any[]) => data.sort((a, b) => a.name.localeCompare(b.name))),
        catchError((err) => of([]))
      )
  }

  getCity() {
    return this.http.get("../../assets/country-state-city/city.json");
  }

  getParam(name) {
    let param = "members";
    let result = name.indexOf("team");
    if (result != -1) {
      param = "groupName";
    }
    return param;
  }

  expandHeight(num) {
    if (num) {
      return num * 100 + 100;
    }
  }

  downloadHttp(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob",
    });
  }

  downloadUrl(url, filename) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status == 200) {
        const blob = this.response;
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 0);
      }
    };
    xhr.send();
  }

  remove(filename) {
    // console.log('remove', filename)
    // const storageRef = this.storage.storage.ref();
    // const desertRef = storageRef.child(filename);
    // desertRef.delete()
    //   .then(() => {
    //     console.log('File deleted successfully')
    //     this.byte = null
    //     this.loading.dismiss()
    //   }).catch((error) => {
    //     alert(JSON.stringify(error))
    //   });
  }

  /**
  * 
  * @param blob 
  * @returns 
  */
  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('Failed to convert Blob to Base64');
        }
      };
      reader.readAsDataURL(blob);
    });
  }
}
