import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reporte-calificacion-silletero-tabla-informacion',
  templateUrl: './reporte-calificacion-silletero-tabla-informacion.component.html',
  styleUrls: ['./reporte-calificacion-silletero-tabla-informacion.component.scss'],
})
export class ReporteCalificacionSilleteroTablaInformacionComponent implements OnInit, OnChanges {

  @Input() tipoSilleta: any;
  @Input() condicionesHabilitantes: any;
  @Input() medidas: any;
  @Input() variedad: any;

  public tabulacion = {
    na: {
      condicionesHabilitantes: {
        variedadFlores: 'cero (0) variedades como mínimo'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 0,
            gt: 0
          },
          mujer: {
            lt: 0,
            gt: 0
          }
        },
        alto: {
          hombre: {
            lt: 0,
            gt: 0
          },
          mujer: {
            lt: 0,
            gt: 0
          }
        }
      }
    },
    silleta_artistica: {
      condicionesHabilitantes: {
        variedadFlores: 'ocho (8) variedades como mínimo'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_comercial: {
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_emblematica: {
      condicionesHabilitantes: {
        variedadFlores: 'veinte (20) variedades como mínimo entre flores y follajes'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_monumental: {
      condicionesHabilitantes: {
        variedadFlores: 'ocho (8) variedades como mínimo'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_pionera: {
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_tradicional: {
      condicionesHabilitantes: {
        variedadFlores: 'Veinte (20) variedades como mínimo'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 45,
            gt: 80
          },
          mujer: {
            lt: 45,
            gt: 80
          }
        },
        alto: {
          hombre: {
            lt: 60,
            gt: 110
          },
          mujer: {
            lt: 60,
            gt: 110
          }
        }
      }
    },
    silleta_junior: {
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
    silleta_infantil: {
      condicionesHabilitantes: {
        variedadFlores: 'ocho (8) variedades como mínimo'
      },
      medidas : {
        ancho: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        },
        alto: {
          hombre: {
            lt: 200,
            gt: 230
          },
          mujer: {
            lt: 180,
            gt: 230
          }
        }
      }
    },
  };

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { condicionesHabilitantes, medidas, tipoSilleta } = changes;

    if (tipoSilleta && tipoSilleta.currentValue) {
      this.tipoSilleta = tipoSilleta.currentValue;
    }

    if (condicionesHabilitantes && condicionesHabilitantes.currentValue) {
      console.log('condicionesHabilitantes', condicionesHabilitantes.currentValue);
      this.condicionesHabilitantes = condicionesHabilitantes.currentValue;
    }

    if (medidas && medidas.currentValue) {
      // console.log('medidas', medidas.currentValue);
      this.medidas = medidas.currentValue;
    }
  }

  get condicionesHabilitantesTab() {
    return this.tabulacion[this.tipoSilleta].condicionesHabilitantes;
  }

  get medidasTab() {
    return this.tabulacion[this.tipoSilleta].medidas;
  }

}
