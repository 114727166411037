import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameSilletas'
})
export class GetNameSilletasPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: any): any {
    if (!value) return value;
    return value.replace(/_/g, ' ');;
  }

}
