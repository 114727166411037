import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/services';
import { SilleterosService } from 'src/app/services/silleteros.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-reporte-calificacion-silletero',
  templateUrl: './reporte-calificacion-silletero.component.html',
  styleUrls: ['./reporte-calificacion-silletero.component.scss'],
})
export class ReporteCalificacionSilleteroComponent implements OnInit, OnDestroy {

  @Input() item: any;
  public code = '394';

  private sub$!: Subscription;

  constructor(
    private silleterosService: SilleterosService,
    private commonSrv: CommonService
  ) {
    const code = this.commonSrv.getParameterByName('code');
    console.log('code', code);
    if (code) { this.code = code; }
  }

  ngOnInit() {
    /**
     * 130 - artistica
     * 381 - comercial
     * 394 - emblematica
     * 73 - monumental
     * 93 - pionera
     * 150 - tradicional
     * i4 - infantil
     * j20 - junior
     * 
     */
    this.sub$ = this.silleterosService.getByIdObservable(this.code)
    .subscribe((data) => {
      console.log('data', data);
      this.item = data;
    })
  }

  get typeSilleta() {
    return (this.item) ? this.item.typeSilleta : 'na';
  }

  get restrictions() {
    return (this.item) ? this.item.restrictions : [];
  }

  get name() {
    return (this.item) ? this.item.name : 'XXXXXXXX XXXXXXXX';
  }

  get cedula() {
    return (this.item) ? this.item.cedula : 'XXXXXXXXX';
  }

  get tipoCategoria() {
    return (this.restrictions && this.restrictions.length > 0) ? this.restrictions[0].title : 'XXXXXXXXX';
  }

  get condicionesHabilitantes() {
    return (this.restrictions.length > 0) ? this.restrictions[0].qualification[0] : null;
  }

  get medidas(){
    return (this.restrictions.length > 0) ? this.restrictions[0].qualification[1] : null;
  }

  get criteriosDeEvaluacion(){
    return (this.restrictions.length > 0) ? this.restrictions[0].qualification[2] : null;
  }

  async downloadPDF(){
    console.log('downloadPDF');
    
    const DATA = document.getElementById('pdfReport');
    console.log('DATA', DATA);

    // const doc = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'letter'});
    const doc = new jsPDF('p', 'pt', 'a4');

    const options = {
      background: 'white',
      scale: 3,
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      console.log('imgProps', imgProps);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

}
