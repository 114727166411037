import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { CustomizationfileService } from '../services/services';

@Pipe({
  name: 'hiddenResults'
})
export class HiddenResultsPipe implements PipeTransform {

  constructor(
    public _cf: CustomizationfileService,
    public db: AngularFireDatabase) { }

  async transform(value): Promise<any> {
    // console.log("value", value)
    const isShow = await this.getHiddenResults(value);
    // console.log("isShow", isShow)
    return isShow;
  }


  /**
   * 
   * @param uid 
   * @returns 
   */
  getHiddenResults(uid) {
    return new Promise((resolve) => {
      // console.log("uid", `/judgesEnabled/${this._cf.getKeyDb()}/users/${uid}/hiddenResults`)
      return this.db.object(`/judgesEnabled/${this._cf.getKeyDb()}/users/${uid}/hiddenResults`)
        .valueChanges()
        .subscribe((data: any) => {
          if (data == null) { return resolve(false) }
          resolve(data);
        });
    })
  }

}
