import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
// import { AppVersion } from '@ionic-native/app-version/ngx';

@Injectable({
  providedIn: "root"
})
export class CustomizationfileService {

  versionNumber = "n/a"
  constructor() {
  }

  getUrlRoot() {
    return `eventDays/${this.getKeyDb()}`;
  }

  getKeyInfo() {
    return window.localStorage.getItem("keyInfo");
  }

  /**
   * @description
   */
  getKeyDb() {
    const keyDb = "e80KrfBzougyucvWjnIi"
    return environment.production ? keyDb : "dev_" + keyDb;
  }

  getUid() {
    return window.localStorage.getItem("uid");
  }

  getName() {
    return window.localStorage.getItem("nameDB")
  }

  getVersion() {
    return "0.1.13"
  }

  getDayConf() {
    return window.localStorage.getItem("dayConf")
  }

  getStageConf() {
    return window.localStorage.getItem("stageConf")
  }

  getStageNameConf() {
    return window.localStorage.getItem("name_day_conf")
  }


  getrol() {
    return JSON.parse(window.localStorage.getItem("rol"));
  }

  getavatar() {
    return window.localStorage.getItem("avatar");
  }

  gettokenPush() {
    return window.localStorage.getItem("tokenPush");
  }

  getprofile() {
    return JSON.parse(window.localStorage.getItem("profile"));
  }
}
